import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation } from "react-router-dom";
import moment from "moment";

import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ClearIcon from '@mui/icons-material/Clear';

import logo from '../Images/logo.png';
import Language from "../Components/LanguageSelector";
import Loading from "../Components/Loading/Loading";
import Progress from "../Components/Loading/Progress";
import PersonalMenu from "../Components/PortalMenu/PersonalMenu";
import PortalMenu from "../Components/PortalMenu/PortalMenu";
import { config, modules } from "../config";
import utils from "../utils";
import { useAuth } from "../Hooks/useAuth";

const drawerWidth = 200;

function LayoutPortal(props) {
	const location = useLocation();
	const { pathname } = location;
	const { logout, token, retrieveUserDetails, userDetails, getUserNotifications, userNotifications, authHeader } = useAuth();
	const [openMainMenu, setOpenMainMenu] = useState(false);
	const [openPersonalMenu, setOpenPersonalMenu] = useState(false);
	const [notificationMenu, setNotificationMenu] = useState(null);
	const openNotificationMenu = Boolean(notificationMenu);
	const theme = useTheme();
	const [expandedMenu, setExpandedMenu] = useState(null)

	useEffect(() => {
        retrieveUserDetails();

		const interval = setInterval(() => {
			getUserNotifications(); // get notifications
		}, 1000 * 60);
	
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
        if(userDetails && !userDetails.role) logout();
	}, [userDetails]);

	useEffect(() => {
		//setOpenMainMenu(false)
		setOpenPersonalMenu(false)
		//setExpandedMenu(null)
	}, [pathname])

    if (!token) {
		return <Navigate to={`/${config.route.root.employee}/${config.route.employee.login}`} />;
    }

	const handleDrawerToggle = () => {
		if(openPersonalMenu) setOpenPersonalMenu(false);
		//setExpandedMenu(null)
		setOpenMainMenu(!openMainMenu);
	};

	const handleDrawerPersonalMenuToggle = (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		if(openMainMenu) setOpenMainMenu(false);
		setOpenPersonalMenu(!openPersonalMenu);
	}

	const handleClickNotifications = (event) => {
		if( userNotifications?.length > 0 && !notificationMenu ) setNotificationMenu(event.currentTarget);
		else setNotificationMenu(null);
	};

	const handleDeleteNotification = id => {
		utils.handleAPIRequest({
            headers: authHeader(),
            url: config.api.employee.notifications,
            method: 'DELETE',
			data: {
				id
			},
            callback: (d) => {
                getUserNotifications();
            }
        })
	}

	const leftDrawer = (
		<>
			<Toolbar />
			{ !userDetails && <Progress /> }
			{ userDetails && <PortalMenu open={openMainMenu} expandedMenu={expandedMenu} setExpandedMenu={setExpandedMenu} /> }
		</>
	);

	const rightDrawer = (
		<Box
			sx={{ width: drawerWidth }}
		>
			<Toolbar />
			{ !userDetails && <Progress /> }
			{ userDetails && <>
			<PersonalMenu />
			<Divider />
			{ modules.languageselector && <Container>
				<Language/>
			</Container> }
			</> }
		</Box>
	);

	const openedMixin = (theme) => ({
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden'
	});
	  
	const closedMixin = (theme) => ({
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: `calc(${theme.spacing(5)} + 1px)`,
		[theme.breakpoints.up('sm')]: {
			width: `calc(${theme.spacing(5)} + 1px)`,
		},
		[theme.breakpoints.down('md')]: {
			display: "none"
		}
	});

	const DrawerCustom = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
		({ theme, open }) => ({
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
			boxSizing: 'border-box',
			...(open && {
				...openedMixin(theme),
				'& .MuiDrawer-paper': openedMixin(theme),
			}),
			...(!open && {
				...closedMixin(theme),
				'& .MuiDrawer-paper': closedMixin(theme),
			}),
		}),
	);

	const handleSideOpen = (e) => {
		if(!openMainMenu) setOpenMainMenu(true)
	}

	return (
		<Box
			sx={{ display: 'flex', flexDirection: { xs: "column", md: "row" } }}
			className={ `container-menu-${openMainMenu ? 'open' : 'close'}` }
		>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					zIndex: "9999",
				}}
			>
				<Toolbar
					sx={{
						bgcolor: theme.palette.header.background,
						height: "64px",
						display: "flex",
						justifyContent: "space-between",
						p: { md: "12px !important" }
					}}
				>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
					>
						<MenuIcon />
					</IconButton>
					<img width="120px" height="40px" src={logo} alt={config.project} />
					<Box>
						{ modules.notifications && <>
						<IconButton
							sx={{ width: 40, height: 40, mr: 1 }}
							onClick={handleClickNotifications}
							aria-controls={openNotificationMenu ? 'account-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={openNotificationMenu ? 'true' : undefined}
						>
							<Badge
								badgeContent={userNotifications?.length}
								color="info"
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								overlap="circular"
							>
							<Avatar>
								{ userNotifications?.length > 0 ? <NotificationsActiveIcon sx={{ width: 22, height: 22 }} /> : <NotificationsIcon sx={{ width: 22, height: 22 }} />}
							</Avatar>
							</Badge>
						</IconButton>
						
						<Menu
							anchorEl={notificationMenu}
							id="account-menu"
							open={openNotificationMenu}
							sx={{
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								width: "300px",
								'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
								'& li:not(:last-of-type)': {
									borderBottom: 1,
									borderColor: "grey.200"
								}
							}}
							transformOrigin={{ horizontal: 'right', vertical: 'top' }}
							anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
						>
							{
								userNotifications?.map(item => <MenuItem sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} key={moment(item.date).milliseconds()}>
									<Box sx={{ width: "250px" }}>
										<Typography variant="body2" sx={{ fontSize: "0.8125rem", whiteSpace: "normal" }}>{ item.message }</Typography>
										<Typography variant="body2" sx={{ fontSize: "0.6rem" }}>{ moment(item.date).format('DD MMM YYYY [alle ore] HH:mm') }</Typography>
									</Box>
									<IconButton onClick={() => handleDeleteNotification(item._id)} size="small"><ClearIcon fontSize="small" /></IconButton>
								</MenuItem>)
							}
						</Menu>
						</> }

						<IconButton
							sx={{ width: 40, height: 40 }}
							onClick={handleDrawerPersonalMenuToggle}
						>
							<Avatar src={userDetails?.profile?.image}>{userDetails?.profile?.firstname.slice(0,1)}{userDetails?.profile?.lastname.slice(0,1)}</Avatar>
						</IconButton>
						<Drawer
							anchor="right"
							open={openPersonalMenu}
							onClose={handleDrawerPersonalMenuToggle}
							ModalProps={{
								keepMounted: true
							}}
						>
							{ rightDrawer }
						</Drawer>
					</Box>
				</Toolbar>
			</AppBar>
			<DrawerCustom
				variant="permanent"
				open={openMainMenu}
				onClick={handleSideOpen}
				onClose={handleDrawerToggle}
			>
				{leftDrawer}
			</DrawerCustom>
			<Box
				component="main"
				sx={{
					flexGrow: 1,
					p: 3,
					position: "relative",
					backgroundColor: "#E7EBF0",
					minHeight: "100vh",
					width: { md: "calc(100% - 5rem)" }
				}}
			>
				<Toolbar />
				{ !userDetails && <Loading /> }
				{ userDetails && <Outlet /> }
			</Box>
		</Box>
	);
}

export default LayoutPortal;