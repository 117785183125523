import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { config } from "../config";
import utils from '../utils';
import moment from 'moment';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(JSON.parse(window.localStorage.getItem('token')));
    const [type, setType] = useState(JSON.parse(window.localStorage.getItem('type')));
    const [userDetails, setUserDetails] = useState(null);
    const [userNotifications, setUserNotifications] = useState(null);
    const [permissions, setUserPermissions] = useState(null);
    const [setting, setSetting] = useState(null);
    const navigate = useNavigate();
	const location = useLocation();

    useEffect(() => {
        if(token !== null && token !== undefined) window.localStorage.setItem('token', JSON.stringify(token));
        else window.localStorage.removeItem('token')

        if(type !== null && type !== undefined) window.localStorage.setItem('type', JSON.stringify(type));
        else window.localStorage.removeItem('type')

    }, [token, type]);

    useEffect(() => {
        let storedData = window.localStorage.getItem('settings');
        if(!storedData) {
            getSettings();
        } else {
            const obj = JSON.parse(storedData);
            if( moment.unix(obj.expires) > moment() ) {
                setSetting(obj.data);
            } else
                getSettings();
        }
	}, []);

    const getSettings = () => {
        const obj = {
            url: config.api[type || "user"].settings,
            method: 'GET',
            callback: (d) => {
                setSetting(d);
                const data = {
                    data: d,
                    expires: moment().add(5, 'minutes').unix()
                }
                window.localStorage.setItem('settings',JSON.stringify(data));
            }
        };
        if( type === 'employee' ) {
            obj.headers = authHeader()
            obj.errorcallback = d => {
                unauthorized(d.status)
            }
        }
        utils.handleAPIRequest(obj)
    }

    const login = async (data, redirect) => {
        setToken(data.token);
        setType(data.type);
        navigate(redirect, { replace: true });
    };

    const logout = (redirect) => {
        setToken(null);
        setType(null);
        window.location.href = redirect || `/`;
        //navigate(redirect || `/`);
    };

    const authHeader = () => {
        return { Authorization: 'Bearer ' + token }
    }

    const unauthorized = status => {
        if( status === "401" ) {
            const employeeLoginUrl = `/${config.route.root.employee}/${config.route.employee.login}`
            const userLoginUrl = `/${config.route.root.user}/${config.route.user.login}`
            const url = type == "employee" ? employeeLoginUrl : userLoginUrl
            if( location.pathname !== employeeLoginUrl && location.pathname !== userLoginUrl ) {
                logout(`${url}?redirect=${encodeURIComponent(location.pathname)}`)
            } else {
                logout(url)
            }
            setToken(null);
            setType(null);
        }
    }

    const retrieveUserDetails = (forceUpdate = false) => {
        if(!userDetails && token || forceUpdate) {
            utils.handleAPIRequest({
                headers: authHeader(),
                url: config.api[type].me,
                method: 'GET',
                callback: (d) => {
                    setTimeout(function(){
                        setUserDetails(d);
                        if(type == "employee") {
                            setUserPermissions(d.permissions);
                            getUserNotifications()
                        }
                    }, 500);
                },
                errorcallback: d => {
                    unauthorized(d.status)
                }
            });
        }
    }

    const getUserNotifications = () => {
        utils.handleAPIRequest({
            headers: authHeader(),
            url: config.api[type].notifications,
            method: 'GET',
            callback: (d) => {
                setUserNotifications(d);
            },
            errorcallback: d => {
                unauthorized(d.status)
            }
        })
    }

    const value = useMemo(
        () => ({
            authHeader,
            login,
            logout,
            retrieveUserDetails,
            getUserNotifications,
            unauthorized,
            permissions,
            token,
            userDetails,
            userNotifications,
            setting
        }),
        [token, userDetails, userNotifications, setting]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};