import React, { useEffect, Suspense, lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import 'moment/locale/it';

import { config, modules } from './config';
import { useAuth } from "./Hooks/useAuth";
import { useError } from "./Hooks/useError";
import Loading from "./Components/Loading/Loading";

import './App.scss';

// LAYOUTS
import Layout from './Layouts/Layout';
import LayoutPortal from './Layouts/LayoutPortal';

// FRONTEND IMPORTS
const NoMatch = lazy(() => import('./Pages/NoMatch/NoMatchPage'));

// CHECKOUT IMPORTS
const CheckoutPage = lazy(() => import('./Pages/Checkout/CheckoutPage'));
const ConfirmationPage = lazy(() => import('./Pages/Checkout/ConfirmationPage'));
const ModifyPage = lazy(() => import('./Pages/Checkout/ModifyPage'));

// PORTAL IMPORTS
const PortalSuperAdmin = lazy(() => import('./Pages/Portal/SuperAdmin/SuperAdmin'));
const PortalLogin = lazy(() => import('./Pages/Portal/Login/LoginPage'));
const PortalForgotPassword = lazy(() => import('./Pages/Portal/ForgotPassword/ForgotPasswordPage'));
const PortalResetPassword = lazy(() => import('./Pages/Portal/ResetPassword/ResetPasswordPage'));
const PortalUpdatePassword = lazy(() => import('./Pages/Portal/UpdatePassword/UpdatePasswordPage'));
const PortalProfile = lazy(() => import('./Pages/Portal/Profile/ProfilePage'));
const PortalActivateAccount = lazy(() => import('./Pages/Portal/ActivateAccount/ActivatePage'));
const PortalDashboard = lazy(() => import('./Pages/Portal/Dashboard/DashboardPage'));
const PortalSettingsSystem = lazy(() => import('./Pages/Portal/Settings/SettingsPage'));
const PortalSettingsBlog = lazy(() => import('./Pages/Portal/Settings/BlogPage'));
const PortalSettingsSite = lazy(() => import('./Pages/Portal/Settings/SitePage'));

const PortalAppointments = lazy(() => import('./Pages/Portal/Appointments/AppointmentsPage'));
const PortalAppointment = lazy(() => import('./Pages/Portal/Appointments/AppointmentPage'));
const PortalReports = lazy(() => import('./Pages/Portal/Reports/ReportsPage'));
const PortalPayments = lazy(() => import('./Pages/Portal/Payments/PaymentsPage'));
const PortalPayment = lazy(() => import('./Pages/Portal/Payments/PaymentPage'));
const PortalUsers = lazy(() => import('./Pages/Portal/Users/UsersPage'));
const PortalUser = lazy(() => import('./Pages/Portal/Users/UserPage'));
const PortalUserNew = lazy(() => import('./Pages/Portal/Users/UserNewPage'));
const PortalProspects = lazy(() => import('./Pages/Portal/Prospects/ResultsPage'));
const PortalForms = lazy(() => import('./Pages/Portal/Prospects/FormsPage'));
const PortalFormNew = lazy(() => import('./Pages/Portal/Prospects/FormNewPage'));
const PortalRegistryUsers = lazy(() => import('./Pages/Portal/Registry/RegistryUsersPage'));
const PortalRegistryUser = lazy(() => import('./Pages/Portal/Registry/RegistryUserPage'));
const PortalRegistryUserNew = lazy(() => import('./Pages/Portal/Registry/RegistryUserNewPage'));

const PortalTypologies = lazy(() => import('./Pages/Portal/Typologies/TypologiesPage'));
const PortalActivities = lazy(() => import('./Pages/Portal/Typologies/ActivitiesPage'));
const PortalCoupon = lazy(() => import('./Pages/Portal/Coupons/CouponPage'));
const PortalCoupons = lazy(() => import('./Pages/Portal/Coupons/CouponsPage'));
const PortalHolidays = lazy(() => import('./Pages/Portal/Holidays/HolidaysPage'));
const PortalEmployees = lazy(() => import('./Pages/Portal/Employees/EmployeesPage'));
const PortalEmployeeProfile = lazy(() => import('./Pages/Portal/Employees/EmployeeProfilePage'));
const PortalEmployeeNew = lazy(() => import('./Pages/Portal/Employees/EmployeeNewPage'));
const PortalLocations = lazy(() => import('./Pages/Portal/Locations/LocationsPage'));
const PortalLocationNew = lazy(() => import('./Pages/Portal/Locations/LocationNewPage'));
const PortalLocationProfile = lazy(() => import('./Pages/Portal/Locations/LocationProfilePage'));

const PortalAvailabilities = lazy(() => import('./Pages/Portal/Availabilities/AvailabilityPage'));
const PortalScheduler = lazy(() => import('./Pages/Portal/Availabilities/SchedulerPage'));

// USER IMPORTS
const UserLogin = lazy(() => import('./Pages/User/Login/LoginPage'));
const UserForgotPassword = lazy(() => import('./Pages/User/ForgotPassword/ForgotPasswordPage'));
const UserResetPassword = lazy(() => import('./Pages/User/ResetPassword/ResetPasswordPage'));
const UserRegistration = lazy(() => import('./Pages/User/Registration/RegistrationPage'));
const UserConfirmAccount = lazy(() => import('./Pages/User/Registration/ConfirmationPage'));
const UserDeleted = lazy(() => import('./Pages/User/Deleted/DeletedPage.js'));
const UserProfile = lazy(() => import('./Pages/User/Profile/ProfilePage'));
const UserPreferences = lazy(() => import('./Pages/User/Preferences/PreferencesPage'));
const UserUpdatePassword = lazy(() => import('./Pages/User/UpdatePassword/UpdatePasswordPage'));
const UserActivateAccount = lazy(() => import('./Pages/User/ActivateAccount/ActivatePage'));
const UserAppointments = lazy(() => import('./Pages/User/Appointments/AppointmentsPage'));
const UserAppointment = lazy(() => import('./Pages/User/Appointments/AppointmentPage'));
const UserReports = lazy(() => import('./Pages/User/Reports/ReportsPage'));
const UserReport = lazy(() => import('./Pages/User/Reports/ReportPage'));
const UserHomepage = lazy(() => import('./Pages/User/Homepage/Homepage'));
const UserInvoices = lazy(() => import('./Pages/User/Invoices/InvoicesPage'));

// E-COMMERCE
const PortalBrands = lazy(() => import('./Pages/Portal/Brands/BrandsPage'));
const PortalBrandNew = lazy(() => import('./Pages/Portal/Brands/BrandNewPage'));
const PortalCategories = lazy(() => import('./Pages/Portal/Categories/CategoriesPage'));
const PortalCategoryNew = lazy(() => import('./Pages/Portal/Categories/CategoryNewPage'));
const PortalTags = lazy(() => import('./Pages/Portal/Tags/TagsPage'));
const PortalTagNew = lazy(() => import('./Pages/Portal/Tags/TagNewPage'));
const PortalProducts = lazy(() => import('./Pages/Portal/Products/ProductsPage'));
const PortalProductNew = lazy(() => import('./Pages/Portal/Products/ProductNewPage'));
const PortalArticles = lazy(() => import('./Pages/Portal/Articles/ArticlesPage'));
const PortalArticleNew = lazy(() => import('./Pages/Portal/Articles/ArticleNewPage'));
const PortalSitemap = lazy(() => import('./Pages/Portal/Sitemap/SitemapPage'));
const PortalGallery = lazy(() => import('./Pages/Portal/Gallery/GalleryPage'));
const PortalLandings = lazy(() => import('./Pages/Portal/Landings/LandingsPage'));
const PortalLandingNew = lazy(() => import('./Pages/Portal/Landings/LandingNewPage'));

export default function App (props) {
	const { permissions, userDetails } = useAuth();
	const location = useLocation();
    const { setGlobalError } = useError();

	useEffect(() => {
		setGlobalError(null);
	}, [location]);

	return <Suspense fallback={<Loading />}>
		<Routes>

			{/* PORTAL ROUTES */}

			{ modules.root.employee && <>

			<Route element={<Layout width="400" />}>
				<Route path={config.route.root.employee}>
					<Route path={config.route.employee.login} element={<PortalLogin />} />
					<Route path={config.route.employee.forgotPassword} element={<PortalForgotPassword />} />
					<Route path={`${config.route.employee.resetPassword}/:id/:token`} element={<PortalResetPassword />} />
					<Route path={config.route.employee.superadmin} element={<PortalSuperAdmin />} />
				</Route>
			</Route>

			<Route path={config.route.root.employee} element={<LayoutPortal {...props} />}>
				<Route path={config.route.employee.dashboard} element={<PortalDashboard />} />
				<Route path={config.route.employee.updatePassword} element={<PortalUpdatePassword />} />
				<Route path={`${config.route.employee.activateAccount}/:code`} element={<PortalActivateAccount />} />
				<Route path={config.route.employee.profile} element={<PortalProfile />} />
				{ userDetails?.role === 1 && <>
					<Route path={config.route.employee.settings} element={<PortalSettingsSystem />} />
				</> }
				{ modules.employee.coupons && permissions?.coupons?.allowed && 
				<>
					<Route path={config.route.employee.coupons} element={<PortalCoupons />} />
					{ (permissions?.coupons?.actions?.add || permissions?.coupons?.actions?.modify) && <Route path={`${config.route.employee.coupons}/:id`} element={<PortalCoupon />} /> }
				</>
				}
				{ modules.employee.services && permissions?.services?.allowed &&
				<>
					<Route path={config.route.employee.typologies} element={<PortalTypologies />} />
					<Route path={`${config.route.employee.typologies}/:id`} element={<PortalActivities />} />
				</>
				}
				{ modules.employee.holidays && permissions?.holidays?.allowed && <Route path={config.route.employee.holidays} element={<PortalHolidays />} /> }
				{ modules.employee.employees && permissions?.employees?.allowed &&
				<>
					<Route path={config.route.employee.employees} element={<PortalEmployees />} />
					{ permissions?.employees?.actions?.add && <Route path={`${config.route.employee.employees}/${config.route.employee.new}`} element={<PortalEmployeeNew />} /> }
					<Route path={`${config.route.employee.employees}/:id`} element={<PortalEmployeeProfile />} />
				</>
				}
				{ modules.employee.locations && permissions?.locations?.allowed &&
				<>
					<Route path={config.route.employee.locations} element={<PortalLocations />} />
					{ permissions?.locations?.actions?.add && <Route path={`${config.route.employee.locations}/${config.route.employee.new}`} element={<PortalLocationNew />} /> }
					<Route path={`${config.route.employee.locations}/:id`} element={<PortalLocationProfile />} />
				</>
				}
				{ modules.employee.availabilities && permissions?.availabilities?.allowed &&
				<>
					<Route path={config.route.employee.availabilities} element={<PortalAvailabilities />} />
					<Route path={`${config.route.employee.availabilities}/:employeeid`} element={<PortalAvailabilities />} />
				</>
				}
				{ modules.employee.scheduler && permissions?.scheduler?.allowed &&
				<>
					<Route path={config.route.employee.scheduler} element={<PortalScheduler />} />
				</>
				}
				{ modules.employee.appointments && permissions?.appointments?.allowed &&
				<>
					<Route path={config.route.employee.appointments} element={<PortalAppointments />} />
					<Route path={`${config.route.employee.appointments}/:type/:id`} element={<PortalAppointments />} />
					<Route path={`${config.route.employee.appointment}/:id`} element={<PortalAppointment />} />
					{ permissions?.appointments?.actions?.add && <Route path={config.route.root.checkout} element={<CheckoutPage />} /> }
				</>
				}
				{ modules.employee.registry && permissions?.registry?.allowed &&
				<>
					<Route path={config.route.employee.registry} element={<PortalRegistryUsers />} />
					{ permissions?.registry?.actions?.add && <Route path={`${config.route.employee.registry}/${config.route.employee.new}`} element={<PortalRegistryUserNew />} /> }
					<Route path={`${config.route.employee.registry}/:id`} element={<PortalRegistryUser />} />
				</> 
				}
				{ modules.employee.reports && permissions?.reports?.allowed &&
				<>
					<Route path={config.route.employee.reports} element={<PortalReports />} />
					<Route path={`${config.route.employee.reports}/:type/:id`} element={<PortalReports />} />
				</> 
				}
				{ modules.employee.payments && permissions?.payments?.allowed &&
				<>
					<Route path={config.route.employee.payments} element={<PortalPayments />} />
					<Route path={`${config.route.employee.payments}/:id`} element={<PortalPayment />} />
				</> 
				}
				{ modules.employee.users && permissions?.users?.allowed &&
				<>
					<Route path={config.route.employee.users} element={<PortalUsers />} />
					{ permissions?.users?.actions?.add && <Route path={`${config.route.employee.users}/${config.route.employee.new}`} element={<PortalUserNew />} /> }
					<Route path={`${config.route.employee.users}/:id`} element={<PortalUser />} />
				</>
				}
				{ modules.employee.prospects && permissions?.prospects?.allowed && 
				<>
					<Route path={config.route.employee.prospects} element={<PortalProspects />} />
					<Route path={config.route.employee.forms} element={<PortalForms />} />
					<Route path={`${config.route.employee.forms}/${config.route.employee.new}`} element={<PortalFormNew />} />
					<Route path={`${config.route.employee.forms}/:id`} element={<PortalFormNew />} />
				</>
				}
				
				<Route path={config.route.employee.brands} element={<PortalBrands />} />
				<Route path={`${config.route.employee.brands}/${config.route.employee.new}`} element={<PortalBrandNew />} />
				<Route path={`${config.route.employee.brands}/:id`} element={<PortalBrandNew />} />
				
				<Route path={config.route.employee.categories} element={<PortalCategories />} />
				<Route path={`${config.route.employee.categories}/${config.route.employee.new}`} element={<PortalCategoryNew />} />
				<Route path={`${config.route.employee.categories}/:id`} element={<PortalCategoryNew />} />

				<Route path={config.route.employee.tags} element={<PortalTags />} />
				<Route path={`${config.route.employee.tags}/${config.route.employee.new}`} element={<PortalTagNew />} />
				<Route path={`${config.route.employee.tags}/:id`} element={<PortalTagNew />} />
				
				<Route path={config.route.employee.products} element={<PortalProducts />} />
				<Route path={`${config.route.employee.products}/${config.route.employee.new}`} element={<PortalProductNew />} />
				<Route path={`${config.route.employee.products}/:id`} element={<PortalProductNew />} />

				<Route path={config.route.employee.articles} element={<PortalArticles />} />
				<Route path={`${config.route.employee.articles}/:type/:id`} element={<PortalArticles />} />
				<Route path={`${config.route.employee.articles}/${config.route.employee.new}`} element={<PortalArticleNew />} />
				<Route path={`${config.route.employee.articles}/:id`} element={<PortalArticleNew />} />
				
				<Route path={config.route.employee.landings} element={<PortalLandings />} />
				<Route path={`${config.route.employee.landings}/${config.route.employee.new}`} element={<PortalLandingNew />} />
				<Route path={`${config.route.employee.landings}/:id`} element={<PortalLandingNew />} />

				{ userDetails?.role === 1 && <>
					<Route path={`${config.route.employee.settings}/blog`} element={<PortalSettingsBlog />} />
					<Route path={`${config.route.employee.settings}/site`} element={<PortalSettingsSite />} />
				</> }

				<Route path={config.route.employee.sitemap} element={<PortalSitemap />} />
				<Route path={config.route.employee.gallery} element={<PortalGallery />} />
				
				<Route path="*" element={<NoMatch />} />
			</Route>

			</> }

			{/* USER ROUTES */}

			{ modules.root.profile && <>

			<Route element={<Layout width="400" hasMenu={true} hasFooter={true} />}>
				<Route path={config.route.root.user}>
					<Route path={config.route.user.login} element={<UserLogin />} />
					<Route path={config.route.user.forgotPassword} element={<UserForgotPassword />} />
					<Route path={`${config.route.user.resetPassword}/:id/:token`} element={<UserResetPassword />} />
					<Route path={config.route.user.registration} element={<UserRegistration />} />
					<Route path={config.route.user.deleted} element={<UserDeleted />} />
				</Route>
			</Route>

			<Route element={<Layout width="600" hasMenu={true} checkSession={true} hasFooter={true} />}>
				<Route path={config.route.root.user}>
					<Route exact path={`/${config.route.root.user}`} element={<UserHomepage />} />
					{ modules.profile.appointments && <Route path={config.route.user.appointments} element={<UserAppointments />} /> }
					{ modules.profile.appointments && <Route path={`${config.route.user.appointments}/:code/:id`} element={<UserAppointment />} /> }
					{ modules.profile.appointments && <Route path={`${config.route.user.appointments}/:id`} element={<UserAppointment />} /> }
					<Route path={config.route.user.profile} element={<UserProfile />} />
					<Route path={config.route.user.preferences} element={<UserPreferences />} />
					<Route path={config.route.user.updatePassword} element={<UserUpdatePassword />} />
					<Route path={`${config.route.user.activateAccount}/:code`} element={<UserActivateAccount />} />
					<Route path={config.route.user.confirmAccount} element={<UserConfirmAccount />} />
					<Route path={config.route.user.invoices} element={<UserInvoices />} />
					{ modules.profile.reports && (!userDetails || userDetails?.settings?.onlinereporting) && <>
						<Route path={config.route.user.reports} element={<UserReports />} />
						<Route path={`${config.route.user.reports}/:code/:id`} element={<UserReport />} />
						<Route path={`${config.route.user.reports}/:id`} element={<UserReport />} />
					</> }
				</Route>
			</Route>

			</> }

			{/* SITE ROUTES */}

			{ modules.root.site && <>

			{ modules.site.checkout && <Route element={<Layout width="600" hasMenu={true} checkSession={true} hasFooter={true} />}>
				<Route path={config.route.root.checkout} element={<CheckoutPage />} />
				<Route path={`${config.route.root.checkout}/${config.route.checkout.confirm}`} element={<ConfirmationPage />} />
				<Route path={`${config.route.root.checkout}/${config.route.checkout.modify}/:code/:id`} element={<ModifyPage />} />
				<Route path={`${config.route.root.checkout}/${config.route.checkout.modify}/:id`} element={<ModifyPage />} />
			</Route> }

			</> }

			<Route element={<Layout width="600" hasMenu={true} hasFooter={true} />}>
				<Route path="*" element={<NoMatch />} />
			</Route>
			
		</Routes>
	</Suspense>
}
