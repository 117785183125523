const i18n = {
    en: {
        "appointments": "Appointments",
        "availabilities": "Availabilities",
        "blog": "Blog",
        "coupons": "Coupons",
        "dashboard": "Dashboard",
        "employees": "Employees",
        "holidays": "Holidays",
        "locations": "Locations",
        "payments": "Payments",
        "reports": "Reports",
        "services": "Services",
        "scheduler": "Scheduler",
        "wordpress": "WordPress Admin",
        "users": "Customers",
        "registry": "Customers registry",
        "personal-holidays": "Personal holidays",
        "profile": "Profile",
        "update-password": "Update password",
        "settings": "Settings",
        "prospects": "Prospects",
        "main-menu": "Main menu",
        "personal-menu": "personal menu",
        "logout": "Logout",
        "tools": "Strumenti",
        "administration": "Amministrazione",
        "products": "Prodotti"
    },
    it: {
        "appointments": "Appuntamenti",
        "availabilities": "Disponibilità",
        "blog": "Blog",
        "coupons": "Coupon",
        "dashboard": "Dashboard",
        "employees": "Dipendenti",
        "holidays": "Ferie",
        "locations": "Sedi",
        "payments": "Pagamenti",
        "reports": "Referti",
        "services": "Servizi",
        "scheduler": "Scheduler",
        "wordpress": "WordPress Admin",
        "users": "Utenti iscritti",
        "registry": "Anagrafica pazienti",
        "personal-holidays": "Ferie personali",
        "profile": "Profilo utente",
        "update-password": "Aggiorna password",
        "settings": "Impostazioni",
        "prospects": "Prospetti",
        "main-menu": "Menu principale",
        "personal-menu": "Menu personale",
        "logout": "Esci",
        "tools": "Strumenti",
        "administration": "Amministrazione",
        "products": "Prodotti"
    }
};

export default i18n;