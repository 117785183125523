const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL
// const API_BASE_URL = "/app"
// const FRONTEND_BASE_URL = "app/"

const config = {
    language: "it",
    project: process.env.REACT_APP_PROJECT_NAME,
    weekdaysOff: '0', // week starts on Sunday = 0
    slotTime: 15,
    api: {
        base: {
            cities: `${API_BASE_URL}/base/cities`,
            taxidcode: `${API_BASE_URL}/base/taxidcode`
        },
        employee: {
            login: `${API_BASE_URL}/employee/login`,
            superadmin: `${API_BASE_URL}/employee/superadmin`,
            forgotPassword: `${API_BASE_URL}/employee/forgot-password`,
            updatePassword: `${API_BASE_URL}/employee/update-password`,
            typologies: `${API_BASE_URL}/employee/typologies`,
            activities: `${API_BASE_URL}/employee/activities`,
            coupons: `${API_BASE_URL}/employee/coupons`,
            holidays: `${API_BASE_URL}/employee/holidays`,
            locations: `${API_BASE_URL}/employee/locations`,
            notifications: `${API_BASE_URL}/employee/notifications`,
            all: `${API_BASE_URL}/employee/all`,
            allByActivity: `${API_BASE_URL}/employee/all-by-activity`,
            profile: `${API_BASE_URL}/employee/profile`,
            me: `${API_BASE_URL}/employee/me`,
            twofactorauth: `${API_BASE_URL}/employee/twofactorauth`,
            emailverification: `${API_BASE_URL}/employee/emailverification`,
            availabilities: `${API_BASE_URL}/employee/availabilities`,
            appointments: `${API_BASE_URL}/employee/appointments`,
            payments: `${API_BASE_URL}/employee/payments`,
            users: `${API_BASE_URL}/employee/users`,
            checkEmployee: `${API_BASE_URL}/employee/check-employee`,
            checkActivity: `${API_BASE_URL}/employee/check-activity`,
            checkTypology: `${API_BASE_URL}/employee/check-typology`,
            checkUser: `${API_BASE_URL}/employee/check-user`,
            dashboard: `${API_BASE_URL}/employee/dashboard`,
            reports: `${API_BASE_URL}/employee/reports`,
            settings: `${API_BASE_URL}/employee/settings`,
            prospects: `${API_BASE_URL}/employee/prospects`,
            registry: `${API_BASE_URL}/employee/registry`,
            categories: `${API_BASE_URL}/employee/categories`,
            brands: `${API_BASE_URL}/employee/brands`,
            tags: `${API_BASE_URL}/employee/tags`,
            products: `${API_BASE_URL}/employee/products`,
            invoices: `${API_BASE_URL}/employee/invoices`,
            articles: `${API_BASE_URL}/employee/articles`,
            sitemap: `${API_BASE_URL}/employee/reload-sitemap`,
            gallery: `${API_BASE_URL}/employee/gallery`,
            forms: `${API_BASE_URL}/employee/forms`,
            notes: `${API_BASE_URL}/employee/notes`,
            agenda: `${API_BASE_URL}/employee/agenda`,
            landings: `${API_BASE_URL}/employee/landings`,
        },
        user: {
            login: `${API_BASE_URL}/user/login`,
            registration: `${API_BASE_URL}/user/register`,
            forgotPassword: `${API_BASE_URL}/user/forgot-password`,
            updatePassword: `${API_BASE_URL}/user/update-password`,
            emailverification: `${API_BASE_URL}/user/emailverification`,
            phoneverification: `${API_BASE_URL}/user/phoneverification`,
            me: `${API_BASE_URL}/user/me`,
            appointments: `${API_BASE_URL}/user/appointments`,
            checkAppointment: `${API_BASE_URL}/user/check-appointment`,
            guestAppointments: `${API_BASE_URL}/user/guest-appointments`,
            twofactorauth: `${API_BASE_URL}/user/twofactorauth`,
            updateProfile: `${API_BASE_URL}/user/update-user`,
            updateSettings: `${API_BASE_URL}/user/update-settings`,
            checkEmail: `${API_BASE_URL}/user/check-email`,
            reports: `${API_BASE_URL}/user/reports`,
            checkReport: `${API_BASE_URL}/user/check-report`,
            guestReports: `${API_BASE_URL}/user/guest-reports`,
            deleteMe: `${API_BASE_URL}/user/delete-me`,
            resetHistory: `${API_BASE_URL}/user/reset-history`,
            settings: `${API_BASE_URL}/user/settings`,
            invoices: `${API_BASE_URL}/user/invoices`,
            payments: `${API_BASE_URL}/user/payments`,
        },
        checkout: {
            activities: `${API_BASE_URL}/checkout/activities`,
            availabilities: `${API_BASE_URL}/checkout/availabilities`,
            employees: `${API_BASE_URL}/checkout/employees`,
            locations: `${API_BASE_URL}/checkout/locations`,
            typologies: `${API_BASE_URL}/checkout/typologies`,
            stripe: `${API_BASE_URL}/checkout/stripe`,
            saveRegistryAppointment: `${API_BASE_URL}/checkout/save-registry-appointment`,
            saveAppointment: `${API_BASE_URL}/checkout/save-appointment`,
            cancelAppointment: `${API_BASE_URL}/checkout/cancel-appointment`,
            coupon: `${API_BASE_URL}/checkout/coupon`
        },
        site: {
            blog: `${API_BASE_URL}/site/blog`,
            categories: `${API_BASE_URL}/site/categories`,
            tags: `${API_BASE_URL}/site/tags`,
            products: `${API_BASE_URL}/site/products`
        }
    },
    route: {
        checkout: {
            confirm: 'conferma',
            modify: 'modifica'
        },
        employee: {
            login: 'accedi',
            superadmin: 'superadmin',
            forgotPassword: 'recupera-password',
            resetPassword: 'reset-password',
            profile: 'profilo-utente',
            updatePassword: 'aggiorna-password',
            activateAccount: 'attiva',
            appointments: 'appuntamenti',
            appointment: 'appuntamento',
            coupons: 'coupon',
            dashboard: 'dashboard',
            employees: 'dipendenti',
            holidays: 'ferie',
            locations: 'sedi',
            typologies: 'tipologie',
            new: 'nuovo',
            availabilities: 'disponibilita',
            scheduler: 'scheduler',
            payments: 'pagamenti',
            users: 'utenti',
            reports: 'referti',
            settings: 'settings',
            prospects: 'prospetti',
            registry: 'anagrafica',
            categories: 'categorie',
            brands: 'brand',
            tags: 'tags',
            products: 'prodotti',
            articles: 'articoli',
            sitemap: 'sitemap',
            gallery: 'galleria',
            forms: 'form',
            landings: 'landing'
        },
        user: {
            login: 'accedi',
            registration: 'registrati',
            forgotPassword: 'recupera-password',
            resetPassword: 'reset-password',
            updatePassword: 'aggiorna-password',
            activateAccount: 'attiva',
            confirmAccount: 'conferma-registrazione',
            profile: 'profilo',
            preferences: 'preferenze',
            appointments: 'appuntamenti',
            reports: 'referti',
            deleted: 'cancellazione',
            invoices: 'fatture'
        },
        site: {
            blog: FRONTEND_BASE_URL + 'blog',
            categories: 'categoria',
            tags: 'tag'
        },
        root: {
            checkout: FRONTEND_BASE_URL + 'prenota',
            employee: FRONTEND_BASE_URL + 'portale',
            user: FRONTEND_BASE_URL + 'cliente'
        },
    }
}

const modules = {
    languageselector: false,
    smsAuth: false,
    notifications: true,
    root: {
        employee: true,
        profile: true,
        site: true
    },
    employee: {
        appointments: true,
        availabilities: true,
        blog: false,
        coupons: true,
        employees: true,
        holidays: true,
        locations: true,
        multimedia: false,
        payments: true,
        products: false,
        prospects: true,
        registry: true,
        reports: false,
        scheduler: true,
        services: true,
        settings: true,
        users: true
    },
    profile: {
        appointments: true,
        reports: false,
        me: true,
        updatepassword: true,
        settings: true
    },
    site: {
        blog: false,
        checkout: true
    }
}

const templateModule = (allowed = false, add = false, modify = false, del = false) => {
    return {
        actions: {
            add,
            delete: del,
            modify
        },
        allowed
    }
}

const templatePermissions = {
    1: {
        label: 'amministratore',
        enabled: true,
        modules: {
            appointments: templateModule(true, true, true, true),
            availabilities: templateModule(true, true, true, true),
            blog: templateModule(true, true, true, true),
            coupons: templateModule(true, true, true, true),
            employees: templateModule(true, true, true, true),
            holidays: templateModule(true, true, true, true),
            locations: templateModule(true, true, true, true),
            multimedia: templateModule(true, true, true, true),
            payments: templateModule(true, true, true, true),
            products: templateModule(true, true, true, true),
            prospects: templateModule(true, true, true, true),
            registry: templateModule(true, true, true, true),
            reports: templateModule(true, true, true, true),
            scheduler: templateModule(true, true, true, true),
            services: templateModule(true, true, true, true),
            settings: templateModule(true, true, true, true),
            users: templateModule(true, true, true, true)
        }
    },
    2: {
        label: 'professionista',
        enabled: true,
        modules: {
            appointments: templateModule(true),
            availabilities: templateModule(true),
            blog: templateModule(),
            coupons: templateModule(),
            employees: templateModule(),
            holidays: templateModule(true),
            locations: templateModule(),
            multimedia: templateModule(),
            payments: templateModule(),
            prospects: templateModule(),
            products: templateModule(),
            registry: templateModule(),
            reports: templateModule(true),
            scheduler: templateModule(),
            services: templateModule(),
            settings: templateModule(),
            users: templateModule()
        }
    },
    3: {
        label: 'segretario',
        enabled: true,
        modules: {
            appointments: templateModule(true, true),
            availabilities: templateModule(true),
            blog: templateModule(),
            coupons: templateModule(),
            employees: templateModule(true),
            holidays: templateModule(true),
            locations: templateModule(),
            multimedia: templateModule(),
            payments: templateModule(true),
            prospects: templateModule(),
            products: templateModule(),
            registry: templateModule(true, true, true, true),
            reports: templateModule(),
            scheduler: templateModule(),
            services: templateModule(),
            settings: templateModule(),
            users: templateModule(true)
        }
    },
    4: {
        label: 'marketing manager',
        enabled: false,
        modules: {
            appointments: templateModule(),
            availabilities: templateModule(),
            blog: templateModule(true, true, true, true),
            coupons: templateModule(true, true, true, true),
            employees: templateModule(),
            holidays: templateModule(),
            locations: templateModule(),
            multimedia: templateModule(),
            payments: templateModule(),
            products: templateModule(true, true, true, true),
            prospects: templateModule(true),
            reports: templateModule(),
            scheduler: templateModule(),
            services: templateModule(),
            settings: templateModule(),
            users: templateModule()
        }
    },
    5: {
        label: 'editor',
        enabled: true,
        modules: {
            appointments: templateModule(),
            availabilities: templateModule(),
            blog: templateModule(true, true, true, true),
            coupons: templateModule(),
            employees: templateModule(),
            holidays: templateModule(),
            locations: templateModule(),
            multimedia: templateModule(true, true, true, true),
            payments: templateModule(),
            prospects: templateModule(),
            products: templateModule(true, true, true, true),
            reports: templateModule(),
            scheduler: templateModule(),
            services: templateModule(),
            settings: templateModule(),
            users: templateModule()
        }
    }
}

export {
    config,
    modules,
    templatePermissions
}